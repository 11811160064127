<template>
    <div v-if="data">
        <template v-if="false && propsValue && propsValue.orderAdjustment && propsValue.orderAdjustment.length > 0">
            <div class="d-flex justify-end align-end">
                <v-btn depressed dark color="primary" @click="openOrderAdjustmentRecordDialog = true">
                    查看訂單調整
                </v-btn>
            </div>
        </template>
        <template>
            <block-title class="d-flex justify-space-between" @click.native="receivingDateInfoCollapse = !receivingDateInfoCollapse">
                <span>訂單詳細資訊</span>
                <v-icon v-if="receivingDateInfoCollapse" color="primary">fa fa-chevron-down</v-icon>
                <v-icon v-else color="primary">fa fa-chevron-up</v-icon>
            </block-title>
            <div v-show="receivingDateInfoCollapse">
                <v-row class="pb-5">
                    <v-col cols="3" class="font-weight-bold">
                        縣市
                    </v-col>
                    <v-col cols="9">
                        {{ orderData.city }}
                    </v-col>
                    <v-col cols="3" class="font-weight-bold">
                        地區
                    </v-col>
                    <v-col cols="9">
                        {{ orderData.district }}
                    </v-col>
                    <v-col cols="3" class="font-weight-bold">
                        收貨地址
                    </v-col>
                    <v-col cols="9">
                        {{ orderData.address }}
                    </v-col>
                    <v-col cols="3" class="font-weight-bold">
                        手機號碼
                    </v-col>
                    <v-col cols="9">
                        {{ orderData.phone }}
                    </v-col>
                </v-row>
            </div>
        </template>
        <block-title>訂購品項</block-title>
        <div class="mb-5" v-if="orderCardData['normal'] && orderCardData['normal'].length > 0">
            <div v-if="!hasBackOrder" class="px-2 py-1 mb-2 font-weight-bold" style="background-color: #FF7D00; color: #fff;">常溫</div>
            <provider-order-card
                v-for="(item, index) in orderCardData['normal']"
                :key="index"
                v-model="orderCardData['normal'][index]"
                :configs="cardConfigs('normal', index)"
                :zebraStriping="Number(index)"
            ></provider-order-card>
        </div>
        <div class="mb-5" v-if="orderCardData['refrigeration'] && orderCardData['refrigeration'].length > 0">
            <div v-if="!hasBackOrder" class="px-2 py-1 mb-2 font-weight-bold" style="background-color: #994b00; color: #fff;">冷藏</div>
            <provider-order-card
                v-for="(item, index) in orderCardData['refrigeration']"
                :key="index"
                v-model="orderCardData['refrigeration'][index]"
                :configs="cardConfigs('refrigeration', index)"
                :zebraStriping="Number(index)"
            ></provider-order-card>
        </div>
        <div class="mb-5" v-if="orderCardData['freezing'] && orderCardData['freezing'].length > 0">
            <div v-if="!hasBackOrder" class="px-2 py-1 mb-2 font-weight-bold" style="background-color: #663200; color: #fff;">冷凍</div>
            <provider-order-card
                v-for="(item, index) in orderCardData['freezing']"
                :key="index"
                v-model="orderCardData['freezing'][index]"
                :configs="cardConfigs('freezing', index)"
                :zebraStriping="Number(index)"
            ></provider-order-card>
        </div>
        <!--  合計  -->
        <number-bar
                v-if="hasBackOrder"
                title="合計"
                unit="元"
                :value="amount"
                :readonly="true"
                name="amount"
                type="primary"
        ></number-bar>

        <div v-else>
            <number-bar
                title="商品總計"
                unit="元"
                :control="false"
                v-model="origin_total_amounts"
                readonly
                name="origin_total_amounts"
                type="secondary"
                style="width: 100%; background-color: #000!important;"
            ></number-bar>
            <number-bar
                v-if="orderCardData['normal'] && orderCardData['normal'].length > 0"
                title="常溫運費"
                unit="元"
                :control="false"
                @change="onChangeDeliveryFee"
                v-model="delivery_fee"
                :readonly="disabled"
                name="delivery_fee"
                type="secondary"
                style="width: 100%; background-color: #FF7D00!important;"
            ></number-bar>
            <number-bar
                v-if="orderCardData['refrigeration'] && orderCardData['refrigeration'].length > 0"
                title="冷藏運費"
                unit="元"
                :control="false"
                @change="onChangeDeliveryFee"
                v-model="refrigeration_delivery_fee"
                :readonly="disabled"
                name="refrigeration_delivery_fee"
                type="secondary"
                style="width: 100%; background-color: #994b00!important;"
            ></number-bar>
            <number-bar
                v-if="orderCardData['freezing'] && orderCardData['freezing'].length > 0"
                title="冷凍運費"
                unit="元"
                :control="false"
                @change="onChangeDeliveryFee"
                v-model="freezing_delivery_fee"
                :readonly="disabled"
                name="freezing_delivery_fee"
                type="secondary"
                style="width: 100%; background-color: #663200!important;"
            ></number-bar>
            <number-bar
                title="含運總計"
                unit="元"
                :control="false"
                reload
                @reload="onReloadAmount"
                @change="onChangeAmount"
                v-model="total_amount"
                :readonly="disabled"
                name="total_amount"
                type="secondary"
                style="width: 100%; background-color: #331900!important;"
            ></number-bar>
        </div>
        <div v-if="logistic_orders && logistic_orders.length > 0" class="mb-5 px-3 pt-1 pb-4" style="border: solid 1px #bababa;">
            <div v-for="(item, index) in logistic_orders" :key="`${item.provider_no}${index}`" class="px-3 py-3 my-2" style="border: solid 1px #bababa;position: relative;">
                <div class="pt-3 pb-1 font-weight-bold">物流商</div>
                <v-select
                    outlined
                    hide-details
                    dense
                    label="請選擇物流商"
                    :items="logisticList"
                    :item-text="`text`"
                    :item-value="`value`"
                    v-model="item.provider_no"
                    :readonly="!(isApply || isCreated)"
                    :name="`logistic_provider[${index}][provider_no]`"
                ></v-select>

                <div class="pt-3 pb-1 font-weight-bold">物流單號</div>
                <v-text-field
                    outlined
                    dense
                    hide-details
                    v-model="item.no"
                    :readonly="!(isApply || isCreated)"
                    :name="`logistic_provider[${index}][no]`"
                ></v-text-field>
                <v-icon v-if="isApply || isCreated" style="position: absolute; top: 5px; right: 5px;" @click="clickRemoveLogisticOrder(index)">ri-close-line</v-icon>
            </div>
            <div v-if="isApply || isCreated" class="d-flex justify-center align-middle py-1" style="border: solid 1px #bababa;" @click="clickAddLogisticOrder">
                <v-icon>ri-add-line</v-icon>
            </div>
        </div>
        <div class="mb-5 px-3 pt-1 pb-4" style="border: solid 1px #bababa;">
            <div class="pt-3 pb-1 font-weight-bold">出貨日期</div>
            <datetime-picker
                type="date"
                format="date"
                v-model="delivery_date"
                name="delivery_date"
                placeholder="請選擇日期"
                :readonly="!(isApply || isCreated)"
            ></datetime-picker>

            <div class="pt-3 pb-1 font-weight-bold">預計到貨天數</div>
            <number-input
              v-model="estimated_delivery_days"
              :max="999"
              name="estimated_delivery_days"
              :readonly="!(isApply || isCreated)"
              :control="isApply || isCreated"
            />
            
            <div class="pt-3 pb-1 font-weight-bold">到貨時間</div>
            <div class="d-flex justify-center align-middle">
                <datetime-picker
                    type="time"
                    format="HH:mm"
                    :range="false"
                    :prependIcon="false"
                    :timePickerOptions="{start: '00:00', step:'00:30' , end: '24:00', format: 'HH:mm' }"
                    v-model="delivery_time_start"
                    name="delivery_time_start"
                    placeholder="請選擇"
                    :readonly="!(isApply || isCreated)"
                ></datetime-picker>
                <span class="px-2 py-3">~</span>
                <datetime-picker
                    type="time"
                    format="HH:mm"
                    :range="false"
                    :prependIcon="false"
                    :timePickerOptions="{start: '00:00', step:'00:30' , end: '24:00', format: 'HH:mm' }"
                    v-model="delivery_time_end"
                    name="delivery_time_end"
                    placeholder="請選擇"
                    :readonly="!(isApply || isCreated)"
                ></datetime-picker>
            </div>
        </div>

        <v-textarea
                v-model="comment"
                v-show="commentVisible"
                label="備註"
                outlined
                name="comment"
                auto-grow
                disabled
        ></v-textarea>
        <v-btn
                depressed
                dark
                color="primary"
                @click="showProviderComment"
                v-show="!providerCommentVisible&&!isAfterSended"
            >
                填寫備註
        </v-btn>
         <v-textarea
                v-model="provider_comment"
                label="供應商備註"
                outlined
                name="provider_comment"
                auto-grow
                v-show="providerCommentVisible"
                :readonly="isAfterSended"
        ></v-textarea>

        <input
                v-for="(item, index) in Object.values(data).flat()"
                :key="`${item.item_id}${index}`"
                hidden
                :name="`items[${index}][item_id]`"
                :value="item.item_id"
        />
        <order-adjustment-record
            :openOrderAdjustmentRecordDialog="openOrderAdjustmentRecordDialog"
            :orderData="propsValue || {}"
            :adjustmentData="propsValue && propsValue.orderAdjustment || []"
            :providerId="providerId"
            :storeId="storeId"
            @updateOrderAdjustmentRecordDialogSwitch="(isOpen) => openOrderAdjustmentRecordDialog = isOpen"
        />
    </div>
</template>

<script lang="babel" type="text/babel">

import countMixins from "@/mixins/count.js"
import orderItemMixins from "@/mixins/orderItem.js";
import OrderAdjustmentRecord from "@/modules/store/views/orderDetail/OrderAdjustmentRecord";
import Moment from 'moment'

export default {
    mixins: [countMixins, orderItemMixins],
    components: {
    "provider-order-card": () => import("@/modules/base/components/priceCard/order/providerOrderCard.vue"),
        "block-title": () => import('@/modules/base/components/blockTitle.vue'),
        "number-bar": () => import('@/modules/base/components/bar/numberBar.vue'),
        "number-input": () => import('@/modules/base/components/numberInput.vue'),
        OrderAdjustmentRecord
    },
    data: () => ({
        data: null,
        orderData: {},
        orderCardData: {},
        comment: null,
        provider_comment: null,
        providerCommentVisible: false,
        percent: 5,
        total_amount: 0,
        commentVisible: false,
        receivingDate: [],
        receivingDateInfoCollapse: true,
        orderItemsCollapse: true,
        openOrderAdjustmentRecordDialog: false
    }),
    watch: {
        propsValue: {
            immediate: true,
            deep: true,
            handler() {
                if (!this.propsValue) return;
                this.data = this.$eagleLodash.cloneDeep({
                    normal: this.propsValue.normal_items,
                    refrigeration: this.propsValue.refrigeration_items,
                    freezing: this.propsValue.freezing_items
                })
                this.comment = this.propsValue.comment
                this.orderData = this.propsValue
                if(this.comment && this.comment.length > 0) {
                    this.commentVisible = true
                }
                this.provider_comment = this.propsValue.provider_comment
                this.propsAmount = this.propsValue.total_amounts
                if(this.propsValue.provider_comment && this.propsValue.provider_comment.length > 0) {
                    this.providerCommentVisible = true
                } else {
                    this.providerCommentVisible = false
                }
                this.delivery_fee = Number(this.propsValue.delivery_fee).toFixed(0)
                this.refrigeration_delivery_fee = Number(this.propsValue.refrigeration_delivery_fee).toFixed(0)
                this.freezing_delivery_fee = Number(this.propsValue.freezing_delivery_fee).toFixed(0)
                this.origin_total_amounts = Number(this.propsValue.origin_total_amounts).toFixed(0)
                this.total_amount = Number(this.propsAmount)
                this.delivery_date = this.isApply ? (this.propsValue.delivery_date || Moment().format('YYYY-MM-DD')) : this.propsValue.delivery_date
                this.estimated_delivery_days = this.isApply ? (this.propsValue.estimated_delivery_days || 1) : this.propsValue.estimated_delivery_days
                this.delivery_time_start = this.isApply ? (this.propsValue.delivery_time_start || '') : this.propsValue.delivery_time_start
                this.delivery_time_end = this.isApply ? (this.propsValue.delivery_time_end || '') : this.propsValue.delivery_time_end
                const logisticData = [...this.propsValue.logisticList.preference, ...this.propsValue.logisticList.arr]
                this.logisticList = logisticData.map(v => ({text: this.propsValue.logisticList.map[v].name,value: v}))
                this.logistic_orders = this.isApply ? 
                    (this.propsValue.logistic_orders && this.propsValue.logistic_orders.length ? this.propsValue.logistic_orders : [{  "no": "", "provider_no": ""}]) :
                    this.propsValue.logistic_orders && this.propsValue.logistic_orders.filter(v => v.no || v.provider_no)
                const dayEnum = [
                  { value: 1, text: '星期一', range: []},
                  { value: 2, text: '星期二', range: []},
                  { value: 3, text: '星期三', range: []},
                  { value: 4, text: '星期四', range: []},
                  { value: 5, text: '星期五', range: []},
                  { value: 6, text: '星期六', range: []},
                  { value: 7, text: '星期日', range: []}
                ]
                this.receivingDate = dayEnum.map((day) => {
                  if (!this.propsValue.receivingDate) return day
                  for (const nowDay of this.propsValue.receivingDate) {
                    if (day.value === nowDay.week && nowDay.times && nowDay.times.length) {
                      day.range = nowDay.times.filter(time => time.start || time.end)
                    }
                  }
                  return day
                })

                console.log('this.receivingDate:', this.receivingDate)
            },
        },
        data: {
            deep: true,
            async handler() {
                for (let dataTemp in this.data) {
                    this.orderCardData[dataTemp] = this.data[dataTemp].map((item, index) => {
                        const price = this.getDiscountPrice({
                            discount_config: item.discount_config,
                            price: Number(item.price).toFixed(0),
                            quantity: Number(item.count).toFixed(0),
                        })

                        let out_of_stock = false
                        // 出貨數量小於訂購數字 -> 缺貨
                        if(item.shipping < item.count) {
                            out_of_stock = true
                        }

                        return {
                            shipping_unit: item.shipping_unit, // 出貨單位
                            calculate_unit: item.calculate_unit, // 計價單位
                            per_shipping_count: item.per_shipping_count, //  ex: 規格:每籃5包
                            shipping_count: this.$helper.reverseShippingCount(item.shipping, item.shipping_unit, 0), // 出貨數量
                            price: Number(item.price).toFixed(0), // 單價
                            order_count: this.$helper.reverseShippingCount(item.origin_shipping, item.shipping_unit, 0), // 訂購數量 / 數量
                            out_of_stock: out_of_stock
                        }
                    })
                }
                
                // 一開始要等子元件渲染完
                await this.$helper.delay(0.5)
                await this.getChildrenTotal()
            }
        },
        orderCardData: {
            deep: true,
            async handler() {
                await this.getChildrenTotal()
            },
        },
        amount: {
            deep: true,
            async handler() {
                await this.reCalcTotalAmount()
            },
        },
    },
    computed: {
        // 訂單狀態
        status() {
            return this.propsValue.status
        },
        // isRequestReturn() {
        //   return ['request_return', 'canceled'].includes(this.status)
        // },
        hasBackOrder() {
            return !!this.propsValue.backOrder
        },
        isCreated() {
            return ['created'].includes(this.status)
        },
        isApply() {
            return ['apply'].includes(this.status)
        },
        isAfterSended() {
            return ['sended', 'finished', 'canceled', 'reject'].includes(this.status)
        },
        disabled() {
            return !['created', 'apply'].includes(this.status)
        },
        usePropsAmount() {
            return !['created', 'apply'].includes(this.status)
        },
        calc_total_amount() {
            return Number(this.amount) + Number(this.delivery_fee) + Number(this.refrigeration_delivery_fee) + Number(this.freezing_delivery_fee)
        },
        providerId() {
            return this.$store.getters[`member/providerId`]
        },
        storeId() {
            return this.$store.getters[`member/storeId`];
        },
    },
    methods: {
        showProviderComment() {
            this.providerCommentVisible = true
        },
        cardConfigs(temp, index) {
            const item = this.data[temp][index]
            const allData = Object.values(this.data).flat()
            const realIndex = allData.indexOf(item)
            if (this.status == 'canceled') {
                return [
                    {id: "product_name", label: item.product, colon: false},
                    {id: "order_count", label: "訂購數量", col: 6},
                    {id: "unit", col: 6},
                ]
            }

            return [
                {id: "product_name", label: item.product, colon: false},
                {id: "order_count", label: "訂購數量", col: 6},
                {id: "unit", col: 6},
                {
                    id: "shipping_count",
                    label: "出貨數量",
                    type: "number-input",
                    fixed: 2,
                    name: `items[${realIndex}][shipping]`,
                    labelColor: "primary--text",
                    disabled: this.disabled,
                    onChange: (value) => this.onChangePrice(temp, realIndex),
                    rule: (count) => this.countRule({count, unit: item.shipping_unit})
                },
                {
                    id: "price",
                    type: "number-input",
                    fixed: 2,
                    name: `items[${realIndex}][price]`,
                    labelColor: "primary--text",
                    valueSuffix: "元",
                    disabled: this.disabled,
                    onChange: (value) => {
                        const discountPrice = this.getDiscountPriceByIndex(realIndex)
                        if (+value > discountPrice) {
                            this.$snotify.error('優惠價格不能高於單價')
                            this.$set(this.orderCardData[temp], realIndex, {...this.orderCardData[temp][index], price: discountPrice})
                        }
                    }
                },
                {
                    id: "total",
                    label: "小計",
                    type: "total",
                    barType: 'primary',
                    fixed: 2,
                    valueSuffix: "元",
                },
            ]
        },
        getDiscountPriceByIndex(temp, index) {
            const dataItem = this.data[temp][index]
            const orderCardItem = this.orderCardData[temp][index]

            return this.getDiscountPrice({
                discount_config: dataItem.discount_config,
                price: +orderCardItem.price,
                quantity: +orderCardItem.shipping_count
            })
        },
        onChangePrice(temp, index) {
            const discountPrice = this.getDiscountPriceByIndex(temp, index)
            const item = this.orderCardData[temp][index]
            const discount_config = this.data[temp][index].discount_config
            console.log('item:', item)
            console.log('discountPrice:', discountPrice)

            if (this.isInDiscountRange(item.shipping_count, discount_config)) {
                const newItem = this.$eagleLodash.cloneDeep(item)
                newItem.price = discountPrice
                this.$set(this.orderCardData[temp], index, newItem)
                return
            } else {
                // 不在優惠區間, 變回原價
                const newItem = this.$eagleLodash.cloneDeep(item)
                newItem.price = this.data[temp][index].price
                this.$set(this.orderCardData[temp], index, newItem)
                return
            }
        },
        async onChangeAmount(value) {
            await this.$nextTick()
            const newAmount = Number(value)
            const oldAmount = this.$children.reduce((arr, cur) => {
                if (cur.total) arr += Number(cur.total)
                return Math.round(arr)
            }, 0);
            // console.log('newAmount', newAmount)
            // console.log('oldAmount', oldAmount)
            if (oldAmount == newAmount) return

            const difference = newAmount - oldAmount

            // console.log('difference', difference)
            // console.log('5%', oldAmount * this.percent)
            if (Math.abs(difference) > (oldAmount * (this.percent / 100))) {
                if (difference > 0) {
                    this.$snotify.error(`總計已高於原本總計的${this.percent}%，請確認調整幅度是否過大`, '總計異常提醒')
                }
                if (difference < 0) {
                    this.$snotify.error(`總計已低於原本總計的${this.percent}%，請確認調整幅度是否過大`, '總計異常提醒')
                }
            }
        },
        onReloadAmount() {
            this.handleResetTotal()
            this.reCalcTotalAmount()
        },
        async onChangeDeliveryFee(value) {
            this.reCalcTotalAmount()
        },
        reCalcTotalAmount() {
          if(['created', 'apply'].includes(this.status))
          {
            this.total_amount = this.$eagleLodash.cloneDeep(this.calc_total_amount)
          }
          else
          {
            this.total_amount = Number(this.amount)
          }
        },
        clickAddLogisticOrder() {
            this.logistic_orders.push({
                no: '',
                provider_no: ''
            })
            this.$set(this, 'logistic_orders', this.logistic_orders)
            this.$forceUpdate()
        },
        clickRemoveLogisticOrder(index) {
            this.logistic_orders.splice(index, 1)
            this.$set(this, 'logistic_orders', this.logistic_orders)
            this.$forceUpdate()
        }
    },
}
</script>

